<template>
  <div>
    <titleBar
      :title="'Monetize '"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <b-field horizontal label=" ">
          <header>
            <h2 class="title is-2">
              <span>Monetize</span>
            </h2>
          </header>
        </b-field>
        <hr />

        <!-- START-->

        <b-field horizontal>
          <div>
            <h3 class="title is-4">Monetization</h3>
          </div>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="bot.enableMonetization" type="is-info">
            Enable Monetization
            <b-tooltip type="is-dark" multilined label="Yup! Your chatbot will post on its own.">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <b-field horizontal v-if="!bot.enableMonetization">
          <div>
            <br />
            <br />
            <br />
            <div class="message is-info">
              <strong>How it works</strong>
              <br />
              It's simple and free to launch your page using the community revenue-share model.

              <!-- -->
              Subscribtion model could yield better revenues for experienced marketers.
              <!-- -->The unlimited model let brands provide a premium experience to their customers.
              <!--
           
              -->
            </div>
          </div>
        </b-field>

        <div v-if="bot.enableMonetization">
          <b-field horizontal label="Pricing">
            <div>
              <b-radio v-model="bot.pricingMode" native-value="community" type="is-info" :disabled="posting">
                Community (earn revenue from users)
                <b-tooltip
                  type="is-dark"
                  multilined
                  label="  You earn when OnlyBots members access your page. Based on popularity, you receive a share of OnlyBots profits"
                >
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-radio>
              <br />
              <b-radio v-model="bot.pricingMode" native-value="subscription" type="is-info" :disabled="posting">
                Subscription
                <b-tooltip type="is-dark" multilined label="Users need to pay you a premium to use your bot. ">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span>
              </b-radio>
              <br />
              <b-radio v-model="bot.pricingMode" native-value="unlimited" type="is-info" :disabled="posting">
                Unlimited
                <b-tooltip type="is-dark" multilined label="Your bot is free to use for users.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span>
              </b-radio>
            </div>
          </b-field>

          <b-field horizontal>
            <b-switch v-model="bot.trialDisabled" type="is-danger">
              No free trial
              <b-tooltip type="is-dark" multilined label="User won't be able to try your bot for a limited time.">
                <i class="fal fa-question-circle" aria-hidden="true"></i>
              </b-tooltip>
            </b-switch>
          </b-field>

          <!-- subscribtion price, drop down from 5us per month to 100$/m -->
          <b-field horizontal label="Subscription price" v-if="bot.pricingMode == 'subscription'">
            <b-select v-model="bot.subscriptionPrice" :disabled="posting">
              <option value="5">5$</option>
              <option value="10">10$</option>
              <option value="15">15$</option>
              <option value="20">20$</option>
              <option value="25">25$</option>
              <option value="30">30$</option>
              <option value="35">35$</option>
              <option value="40">40$</option>
              <option value="45">45$</option>
              <option value="50">50$</option>
              <option value="55">55$</option>
              <option value="60">60$</option>
              <option value="65">65$</option>
              <option value="70">70$</option>
              <option value="75">75$</option>
              <option value="80">80$</option>
              <option value="85">85$</option>
              <option value="90">90$</option>
              <option value="95">95$</option>
              <option value="100">100$</option>
            </b-select>
          </b-field>

          <!-- buefy switch , about monetization yes no
      <b-field horizontal>
        <b-switch v-model="bot.monetized" type="is-info">
          Monetize
          <b-tooltip
            type="is-dark"
            multilined
            label="Your chatbot will be listed in the public directory, and users will be able to find it."
          >
            <i class="fal fa-question-circle" aria-hidden="true"></i>
          </b-tooltip>
        </b-switch>
      </b-field> -->

          <!-- 
      <b-field horizontal label="Mode">
        <b-select v-model="bot.monetizationMode" placeholder="Select a mode" :disabled="posting">
          <option value="demo">Demo</option>
          <option value="pro">Pro</option>
          <option value="unlimited">Unlimited</option>
        </b-select>
      </b-field> -->

          <b-field horizontal label="subscriptions" v-if="bot.pricingMode == 'subscription'">
            <div class="box">
              <span class="tag is-warning is-light">Premium plan required</span><br /><br />
              <p>Earn from subscrbers</p>
              <p>Set your prices & your rules for your AI content.</p>
              <!-- subsctibe button is dark, router link to /:bot/plans -->
              <br />
              <router-link to="/bot/plans" class="button is-dark"> Pricing</router-link>
            </div>
          </b-field>

          <b-field horizontal label="Unlimited access" v-if="bot.pricingMode == 'unlimited'">
            <div class="box">
              <span class="tag is-warning is-light">Premium plan required</span><br /><br />
              <p>Provide unlimited access to your bots</p>
              <p>The premium plan will let thoushands of people interact with your bot for free every month.</p>
              <!-- subsctibe button is dark, router link to /:bot/plans -->
              <br />
              <router-link to="/bot/plans" class="button is-dark">Pricing</router-link>
            </div>
          </b-field>

          <!-- END     
    -->
          <b-field horizontal label="   ">
            <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
              >Save changes</b-button
            >
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
